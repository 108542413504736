import React, { useEffect } from 'react';
import './Projects.css';

const Projects = () => {

    useEffect(() => {
        const filters = document.querySelectorAll(".filter");
        const elements = document.querySelectorAll(".element__ready__single");

        const handleClick = function () {
            const selectedFilter = this.getAttribute("data-filter");

            elements.forEach(function (element) {
                const categories = element.classList;

                if (selectedFilter === "*" || categories.contains(selectedFilter.slice(1))) {
                    element.style.display = "block";
                } else {
                    element.style.display = "none";
                }
            });

            // Fjern .active klassen fra alle filtre og tilføj den til det valgte filter
            filters.forEach(function (filter) {
                filter.classList.remove("active");
            });
            this.classList.add("active");
        };

        filters.forEach(function (filter) {
            filter.addEventListener("click", handleClick);
        });

        return () => {
            filters.forEach(function (filter) {
                filter.removeEventListener("click", handleClick);
            });
        };
    }, []); // tøm afhængighedsarrayet for kun at køre denne effekt ved montering og afmontering af komponenten

    return (
        <div className="elementor-section-boxed" id='projekter'>
            <h3 className="area-title">Projects</h3>
            <div className="sldier-content-area">
                <div className="filter__menu">
                    <ul>
                        <li className="filter" data-filter="*">All</li>
                        <li className="filter" data-filter=".Job">Job</li>
                        <li className="filter" data-filter=".Cases">Cases</li>
                        <li className="filter" data-filter=".Figma">Figma</li>
                    </ul>
                </div>
                <div className="element-ready-filter">
                    <div className="element__ready__single Job">
                        <div className="portfolio__thumb">
                                <img decoding="async" src="./assets/images/Himmerlands 381.png" alt="Himmerlands"/>
                                <a target='_blank' rel="noreferrer" href='https://github.com/michaelkp123'><img className='icon-github' src='./assets/images/github.png' alt="github"/></a>
                                <a target='_blank' rel="noreferrer" href='https://himmerlandspejsecenter.dk/'><img className='icon-internet' src='./assets/images/internet.png' alt="internet"/></a>
                        </div>
                    </div>

                    <div className="element__ready__single Job">
                        <div className="portfolio__thumb">
                                <img decoding="async" src="./assets/images/Claritide.png" alt="claritistore.de"/>
                                <a target='_blank' rel="noreferrer" href='https://claritistore.de/'><img className='icon-internet' src='./assets/images/internet.png' alt="internet"/></a>
                        </div>
                    </div>

                    <div className="element__ready__single Job">
                        <div className="portfolio__thumb">
                                <img decoding="async" src="./assets/images/Clariticom.png" alt="claritistore.com"/>
                                <a target='_blank' rel="noreferrer" href='https://claritistore.com/'><img className='icon-internet' src='./assets/images/internet.png' alt="internet"/></a>
                        </div>
                    </div>

                    <div className="element__ready__single Job">
                        <div className="portfolio__thumb">
                                <img decoding="async" src="./assets/images/storståhej.png" alt="staahej.dk"/>
                                <a target='_blank' rel="noreferrer" href='https://staahej.dk/'><img className='icon-internet' src='./assets/images/internet.png' alt="internet"/></a>
                        </div>
                    </div>

                    <div className="element__ready__single Job">
                        <div className="portfolio__thumb">
                                <img decoding="async" src="./assets/images/netpuds 381.png" alt="netpuds"/>
                                <a target='_blank' rel="noreferrer" href='https://github.com/michaelkp123'><img className='icon-github' src='./assets/images/github.png' alt="github"/></a>
                                <a target='_blank' rel="noreferrer" href='https://urbanhald.dk/shop/netpuds-og-facadepuds-92c1.html'><img className='icon-internet' src='./assets/images/internet.png' alt="internet"/></a>
                        </div>
                    </div>

                    <div className="element__ready__single Cases">
                        <div className="portfolio__thumb">
                                <img decoding="async" src="./assets/images/Tribble 381.png" alt="Tribble"/>
                                <a target='_blank' rel="noreferrer" href='https://github.com/michaelkp123/TribbleLadeApp'><img className='icon-github' src='./assets/images/github.png' alt="github"/></a>
                                <a target='_blank' rel="noreferrer" href='https://github.com/michaelkp123/TribbleLadeApp'><img className='icon-internet' src='./assets/images/internet.png' alt="internet"/></a>
                        </div>
                    </div>

                    <div className="element__ready__single Job">
                        <div className="portfolio__thumb">
                                <img decoding="async" src="./assets/images/trægulv 381.png" alt="trægulv"/>
                                <a target='_blank' rel="noreferrer" href='https://github.com/michaelkp123'><img className='icon-github' src='./assets/images/github.png' alt="github"/></a>
                                <a target='_blank' rel="noreferrer" href='https://urbanhald.dk/shop/traegulv-beregner-80c1.html'><img className='icon-internet' src='./assets/images/internet.png' alt="internet"/></a>
                        </div>
                    </div>
                    <div className="element__ready__single Cases">
                        <div className="portfolio__thumb">
                                <img decoding="async" src="./assets/images/exam-app-381.png" alt="exam-app"/>
                                <a target='_blank' rel="noreferrer" href='https://github.com/michaelkp123/REST-app-for-teacher'><img className='icon-github' src='./assets/images/github.png' alt="github"/></a>
                                <a target='_blank' rel="noreferrer" href='https://adv-web-program-exam.onrender.com'><img className='icon-internet' src='./assets/images/internet.png' alt="internet"/></a>
                        </div>
                    </div>
                    <div className="element__ready__single Cases">
                        <div className="portfolio__thumb">
                                <img decoding="async" src="./assets/images/360-app-381.png" alt="360image"/>
                                <a target='_blank' rel="noreferrer" href='https://github.com/michaelkp123/360Examv2'><img className='icon-github' src='./assets/images/github.png' alt="github"/></a>
                                <a target='_blank' rel="noreferrer" href='https://youtu.be/Ox_QThKQC14'><img className='icon-internet' src='./assets/images/internet.png' alt="internet"/></a>
                        </div>
                    </div>
                    <div className="element__ready__single Figma">
                        <div className="portfolio__thumb">
                                <img decoding="async" src="./assets/images/Figma-Food.png" alt="Figma food"/>
                                <a target='_blank' rel="noreferrer" href='https://www.figma.com/file/E8OyA8naTXQhcKi1ChWJpW/Wireframe-for-food-project?type=design&node-id=3%3A186&mode=design&t=qTaKQcG5px5wRVwp-1'><img className='icon-github' src='./assets/images/figma-svgrepo-com.png' alt="github"/></a>
                                <a target='_blank' rel="noreferrer" href='https://www.figma.com/proto/E8OyA8naTXQhcKi1ChWJpW/Wireframe-for-food-project?page-id=3%3A186&type=design&node-id=4-65&viewport=120%2C478%2C0.55&t=6wMUKq1uxunUq4A0-1&scaling=scale-down&starting-point-node-id=4%3A65&mode=design'><img className='icon-internet' src='./assets/images/internet.png' alt="internet"/></a>
                        </div>
                    </div>
                    <div className="element__ready__single Figma">
                        <div className="portfolio__thumb">
                                <img decoding="async" src="./assets/images/Marvel-app.png" alt="Figma marvel"/>
                                <a target='_blank' rel="noreferrer" href='https://www.figma.com/file/mvhlaaHboP8V0ZJAqGqfZz/MCU-Over-the-top?type=design&node-id=1%3A120&mode=design&t=CcjlFdTnOiDVDCj1-1'><img className='icon-github' src='./assets/images/figma-svgrepo-com.png' alt="github"/></a>
                                <a target='_blank' rel="noreferrer" href='https://www.figma.com/proto/mvhlaaHboP8V0ZJAqGqfZz/MCU-Over-the-top?page-id=1%3A120&type=design&node-id=1-121&viewport=299%2C506%2C0.28&t=N4sRLOKyU7P0yF8J-1&scaling=min-zoom&starting-point-node-id=1%3A121&mode=design'><img className='icon-internet' src='./assets/images/internet.png' alt="internet"/></a>
                        </div>
                    </div>
                    <div className="element__ready__single Figma">
                        <div className="portfolio__thumb">
                                <img decoding="async" src="./assets/images/Music-app.png" alt="Figma marvel"/>
                                <a target='_blank' rel="noreferrer" href='https://www.figma.com/file/zuwHIqmwlvOMSauUecSPkS/Music-app?type=design&node-id=2%3A189&mode=design&t=wJ7vzwLt0DISiiJS-1'><img className='icon-github' src='./assets/images/figma-svgrepo-com.png' alt="github"/></a>
                                <a target='_blank' rel="noreferrer" href='https://www.figma.com/proto/zuwHIqmwlvOMSauUecSPkS/Music-app?page-id=2%3A189&type=design&node-id=2-570&viewport=791%2C445%2C0.51&t=UlbuUmH2NT2aBtzN-1&scaling=min-zoom&starting-point-node-id=2%3A570&mode=design'><img className='icon-internet' src='./assets/images/internet.png' alt="internet"/></a>
                        </div>
                    </div>
                    <div className="element__ready__single Figma">
                        <div className="portfolio__thumb">
                                <img decoding="async" src="./assets/images/Social-media.png" alt="Figma marvel"/>
                                <a target='_blank' rel="noreferrer" href='https://www.figma.com/file/MhLW59Sdk2flsFlXGL6Yvo/Social-media-website?type=design&node-id=1%3A58&mode=design&t=C2cemtY0RfNUwPgf-1'><img className='icon-github' src='./assets/images/figma-svgrepo-com.png' alt="github"/></a>
                                <a target='_blank' rel="noreferrer" href='https://www.figma.com/proto/MhLW59Sdk2flsFlXGL6Yvo/Social-media-website?page-id=1%3A58&type=design&node-id=1-59&viewport=863%2C459%2C0.58&t=tLX2gwhhiZaFiFRC-1&scaling=min-zoom&mode=design'><img className='icon-internet' src='./assets/images/internet.png' alt="internet"/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Projects;